export const TOOLBAR_HEIGHT = 75;
export const MOBILE_TOOLBAR_HEIGHT = 100;

export const SM_SCREEN_MAX = 776;
export const MD_SCREEN_MAX = 1048;

export const SUPPORT_URL = 'http://support.nextcenturymeters.com/';
export const WEB_PORTAL_URL = 'http://app.nextcenturymeters.com/';
export const ROOT_URL = 'https://api.nextcenturymeters.com/';
// export const ROOT_URL = 'http://localhost:3000/';
export const SENDY_URL = 'https://news.nextcenturymeters.com/';

export enum Links {
  SUPPORT = 1,
  CONTACT = 2,
  SIGN_IN = 3,
  CAREERS = 4,
  PRODUCTS = 5,
  APPLICATIONS = 7,
  HOW_IT_WORKS = 8,
  HOME = 9,
  NM4I = 10,
}

export const LinkSectionIds: { [key in Links]?: string } = {
  [Links.APPLICATIONS]: 'applications',
  [Links.HOW_IT_WORKS]: 'howItWorks',
  [Links.PRODUCTS]: 'ourSolution',
  [Links.CONTACT]: 'questions',
};

export const ExternalLinks: { [key in Links]?: string } = {
  [Links.SUPPORT]: SUPPORT_URL,
  [Links.SIGN_IN]: WEB_PORTAL_URL,
};

export interface IModel {
  name: string;
  open?: boolean;
  hyperlink?: { label: string, url: string };
  specSheet?: string;
  hardwareWarranty?: string;
  installationGuide?: string;
  certifications?: Array<{ label: string, url: string }>;
  disableLinks?: boolean;
}
export interface ProductTab {
  title: string;
  descriptionHTML?: string;
  models?: IModel[];
  learnMoreUrl?: string;
  photo?: string;
  sliderImg: string;
  subTabs?: ProductTab[];
}

export interface DeviceCount {
  activeDevices: number
}

export const ProductTabs: ProductTab[] = [
  {
    title: 'Water Meter',
    descriptionHTML: 'The NextMeter combines cutting-edge ultrasonic technology with the industry-leading AMR solution, all in a simple and easy-to-use meter designed for effortless deployment that is NTEP certified for hot/cold and horizontal/vertical orientation.',
    photo: 'assets/equipment/nm4-i.jpg',
    models: [
      {
        name: 'NextMeter NM4-I (Indoor)',
        specSheet: 'https://docs.nextcenturymeters.com/NM4-I-Installation-Guide.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-NM4-I-Water-Meter-Warranty.pdf',
        installationGuide: 'https://docs.nextcenturymeters.com/NM4-I-Quick-Start-Guide.pdf',
        certifications: [
          { label: 'IC Certification', url: 'https://docs.nextcenturymeters.com/IC-NM4-I.pdf'},
          { label: 'FCC Certification', url: 'https://docs.nextcenturymeters.com/FCC-NM4-I.pdf'},
          { label: 'NTEP Certification', url: 'https://docs.nextcenturymeters.com/NTEP-Certification-NM4-I.pdf'},
          { label: 'UL 2043', url: 'https://docs.nextcenturymeters.com/UL-2043-NM4-I.pdf'},
          { label: 'MA Accepted Plumbing Products', url: 'https://ma-dpl.my.salesforce-sites.com/MAPlumbingProducts/ppPlumbManFinal'},
          { label: 'NSF/ANSI/CAN 61', url: 'https://info.nsf.org/Certified/PwsComponents/Listings.asp?Company=C0759763&Standard=061'}
          
        ]
      },
      {
        name: 'M201C (Cold)',
        specSheet: 'https://docs.nextcenturymeters.com/NextCentury-M201C-Meter-Specification-Sheet.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-M201-Water-Meter-Warranty.pdf',
        installationGuide: 'https://docs.nextcenturymeters.com/M201-Water-Meter-Installation-Instructions.pdf',
        certifications: [
          { label: 'NTEP Certification', url: 'https://docs.nextcenturymeters.com/NTEP-Certification-15-045A1-M201C-and-M201CH.pdf' },
          { label: 'NSF-61 and ANSI-371 Certification', url: 'https://docs.nextcenturymeters.com/NSF-61-and-ANSI-372-Certification.pdf' },
          { label: 'MA Accepted Plumbing Products', url: 'https://licensing.reg.state.ma.us/pubLic/pl_products/pb_search.asp?type=P&manufacturer=Next+Century+Submetering+Systems&stype=spec&model=&product=&description=&psize=50'},
        ]
      },
      {
        name: 'M201CH (Hot)',
        specSheet: 'https://docs.nextcenturymeters.com/NextCentury-M201CH-Meter-Specification-Sheet.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-M201-Water-Meter-Warranty.pdf',
        installationGuide: 'https://docs.nextcenturymeters.com/M201-Water-Meter-Installation-Instructions.pdf',
        certifications: [
          { label: 'NTEP Certification', url: 'https://docs.nextcenturymeters.com/NTEP-Certification-15-045A1-M201C-and-M201CH.pdf' },
          { label: 'NSF-61 and ANSI-371 Certification', url: 'https://docs.nextcenturymeters.com/NSF-61-and-ANSI-372-Certification.pdf' },
          { label: 'MA Accepted Plumbing Products', url: 'https://licensing.reg.state.ma.us/pubLic/pl_products/pb_search.asp?type=P&manufacturer=Next+Century+Submetering+Systems&stype=spec&model=&product=&description=&psize=50'},
        ]
      }
    ],
    learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/categories/360002473771?sectionId=360007622931',
    sliderImg: 'assets/drawings/next-meter-drawing.png',
  },
  {
    title: 'Meter Devices',
    sliderImg: 'assets/drawings/transceiver-drawing.png',
    subTabs: [
      {
        photo: 'assets/equipment/transceiver.jpg',
        title: 'Indoor Transceiver',
        descriptionHTML: `Our TR4 Transceiver will easily transform nearly any meter into a smart wireless meter, enabling it to automatically connect to the NextCentury Cloud Platform via <span class="tooltip-link">Meter+ Technology<div class="tip"><span class="italic">Meter+ Technology</span> has been developed to allow communication with virtually all modern submeter outputs. These include all major pulse-out and encoded output meters. <span class="italic">Meter+ Technology</span> supports Sensus, Neptune, Amco, Elster, and GWF 3-wire protocols. Simply connect the Transceiver to the meter and it will automatically detect which protocol to use. The <span class="italic">Meter+ Smart Pulse</span> detection allows the Transceiver to detect, correct, and report faulty reed switches.</div></span>. The TR4 provides hourly read precision over a 10 mile wireless range, with a standard 10-year replaceable battery life.`,
        models: [
          {
            name: 'TR4',
            specSheet: 'https://docs.nextcenturymeters.com/TR4-Installation-Guide.pdf',
            hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
            installationGuide: 'https://docs.nextcenturymeters.com/TR4-Quick-Start.pdf',
            certifications: [
              { label: 'ISED Certification for TR4', url: 'http://docs.nextcenturymeters.com/TR4-ISED-Certification.pdf' },
              { label: 'FCC Certification for TR4', url: 'https://docs.nextcenturymeters.com/TR4-FCC-Certification.pdf' },
            ],
          },
        ],
        learnMoreUrl: 'https://products.nextcenturymeters.com/tr4',
        sliderImg: 'assets/drawings/transceiver-drawing.png',
      },
      {
        photo: 'assets/equipment/transceiver-outdoor.jpg',
        title: 'Outdoor Transceiver',
        descriptionHTML: 'Our IP68 rated TR4-X Transceiver will easily transform nearly any outdoor meter into a smart wireless meter, enabling it to automatically connect to the NextCentury Cloud Platform via <span class="tooltip-link">Meter+ Technology<div class="tip"><span class="italic">Meter+ Technology</span> has been developed to allow communication with virtually all modern submeter outputs. These include all major pulse-out and encoded output meters. <span class="italic">Meter+ Technology</span> supports Sensus, Neptune, Amco, Elster, and GWF 3-wire protocols. Simply connect the Transceiver to the meter and it will automatically detect which protocol to use. The <span class="italic">Meter+ Smart Pulse</span> detection allows the Transceiver to detect, correct, and report faulty reed switches.</div></span>. The TR4-X provides hourly read precision over a 10 mile wireless range, with a standard 10-year replaceable battery life.',
        learnMoreUrl: SUPPORT_URL,
        sliderImg: 'assets/drawings/transceiver-outdoor-drawing.png',
        models: [
          {
            name: 'TR4-X',
            specSheet: 'https://docs.nextcenturymeters.com/TR4-X-Installation-Guide.pdf',
            hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
            installationGuide: 'https://docs.nextcenturymeters.com/TR4-X-Quick-Start-Guide.pdf',
            certifications: [
              { label: 'ISED Certification for TR4-X', url: 'https://docs.nextcenturymeters.com/IC-TR4-X.pdf' },
              { label: 'FCC Certification for TR4-X', url: 'https://docs.nextcenturymeters.com/FCC-TR4-X.pdf' },
            ],
          },
        ],
      },
      {
        photo: 'assets/equipment/remote-reader.jpg',
        title: 'Remote Reader',
        descriptionHTML: 'Our RR4 Remote Reader provides an easy to read display for hard to reach meters. With dual <span class="tooltip-link">Meter+ Technology<div class="tip"><span class="italic">Meter+ Technology</span> has been developed to allow communication with virtually all modern submeter outputs. These include all major pulse-out and encoded output meters. <span class="italic">Meter+ Technology</span> supports Sensus, Neptune, Amco, Elster, and GWF 3-wire protocols. Simply connect the Transceiver to the meter and it will automatically detect which protocol to use. The <span class="italic">Meter+ Smart Pulse</span> detection allows the Transceiver to detect, correct, and report faulty reed switches.</div></span> it can connect with up to two meters at once and always provides a convenient display for tenants to view their usage.',
        models: [
          {
            name: 'RR4-TR (Integrated Transceiver)',
            specSheet: 'https://docs.nextcenturymeters.com/RR4-TR-Installation-Guide.pdf',
            hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
            installationGuide: 'http://docs.nextcenturymeters.com/RR4-TR-Quick-Start-Guide.pdf',
            certifications: [
              { label: 'NTEP Certification RR4-TR', url: 'http://docs.nextcenturymeters.com/NTEP-Certification-RR4.pdf' },
              { label: 'FCC Certification for RR4-TR', url: 'https://docs.nextcenturymeters.com/RR4-FCC-Certification.pdf' },
              { label: 'IC Certification for RR4-TR', url: 'https://docs.nextcenturymeters.com/RR4-IC-Certification.pdf' },
            ],
          },
          {
            name: 'RR4',
            specSheet: 'https://docs.nextcenturymeters.com/RR4-Installation-Guide.pdf',
            hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
            installationGuide: 'http://docs.nextcenturymeters.com/RR4-Quick-Start-Guide.pdf',
            certifications: [
              { label: 'NTEP Certification RR4', url: 'http://docs.nextcenturymeters.com/NTEP-Certification-RR4.pdf' },
              { label: 'FCC Certification for RR4', url: 'https://docs.nextcenturymeters.com/RR4-FCC-Certification.pdf' },
              { label: 'IC Certification for RR4', url: 'https://docs.nextcenturymeters.com/RR4-IC-Certification.pdf' },
            ],
          },
        ],
        learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/articles/360062435391-RR4-TR-Remote-Reader-Overview-and-Documents',
        sliderImg: 'assets/drawings/remote-reader-drawing.png',
      },
    ],
  },
  {
    photo: 'assets/equipment/leak-sensor.jpg',
    title: 'Leak Sensor',
    descriptionHTML: 'Our LS4 and Leak Monitoring Solution works seamlessly with existing NCSS installations, making it the simplest and most reliable path to adopting a leak protection solution at your property. With flexible alert notifications the responsible parties will receive a phone call, text message, and/or email whenever a leak is detected. Tenants will also be alerted to the leak with a simple audio chirp. Mix and match sensor cables to best fit your application.',
    models: [
      {
        name: '',
        hyperlink: {
          label: 'Leak Monitoring ROI Calculator',
          url: 'https://nextcenturymeters.com/leak-roi',
        },
      },
      {
        name: 'LS4',
        installationGuide: 'https://docs.nextcenturymeters.com/LS4-Quick-Start-Guide.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
        specSheet: 'https://docs.nextcenturymeters.com/LS4-Installation-Guide.pdf',
        certifications: [
          { label: 'FCC Certification for LS4', url: 'https://docs.nextcenturymeters.com/FCC-LS4.pdf' },
          { label: 'ISED Certification for LS4', url: 'https://docs.nextcenturymeters.com/IC-LS4.pdf' },
        ],
      },
    ],
    learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/articles/9807364463252-LS4-Leak-Sensor-Overview-Documents',
    sliderImg: 'assets/drawings/leak-sensor-drawing.png',
  },
  {
    photo: 'assets/equipment/repeater.jpg',
    title: 'Repeater',
    descriptionHTML: 'For large properties, like multifamily apartment buildings, our RE4 Repeaters can be added to boost the wireless network.  NextCentury wireless technology is known for being capable of enormous networks with outstanding signal integrity. Designed to make installation and maintenance simple.',
    models: [
      {
        name: 'RE4',
        specSheet: 'https://docs.nextcenturymeters.com/RE4-Installation-Guide.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
        installationGuide: 'https://docs.nextcenturymeters.com/RE4-Quick-Start-Guide.pdf',
        certifications: [
          { label: 'FCC Certification for RE4', url: 'https://docs.nextcenturymeters.com/FCC-RE4.pdf' },
          { label: 'ISED Certification for RE4', url: 'https://docs.nextcenturymeters.com/IC-RE4.pdf' },
        ],
      },
    ],
    learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/articles/360034905371-RE201-Repeater-Overview-and-Documents',
    sliderImg: 'assets/drawings/repeater-drawing.png',
  },
  {
    photo: 'assets/equipment/gateway.jpg',
    title: 'Gateway',
    descriptionHTML: 'Our GW4 Gateway connects Transceivers to the NextCentury Cloud.  With a built-in 5G/LTE cellular modem, ethernet, and wifi capabilities the GW4 can be easily installed and immediately send usage data to the Cloud. The GW4 can wirelessly connect with hundreds or even thousands of endpoints, or use our GW4-L for small properties.',
    models: [
      {
        name: 'GW4',
        specSheet: 'https://docs.nextcenturymeters.com/GW4-Installation-Guide.pdf',
        hardwareWarranty: 'https://docs.nextcenturymeters.com/NextCentury-Data-Gathering-System-Warranty.pdf',
        installationGuide: 'https://docs.nextcenturymeters.com/GW4-Quick-Start-Guide.pdf',
        certifications: [
          { label: 'FCC Certification for GW4', url: 'https://docs.nextcenturymeters.com/FCC-GW4.pdf' },
          { label: 'ISED Certification for GW4', url: 'https://docs.nextcenturymeters.com/IC-GW4.pdf' },
        ],
      },
    ],
    learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/articles/360034907351-GW301-Cellular-Gateway-Overview-and-Documents',
    sliderImg: 'assets/drawings/gateway-drawing.png',
  },
  {
    photo: 'assets/equipment/web.jpg',
    title: 'Cloud & Data Services',
    descriptionHTML: 'Increase efficiency and lower operating costs with additional NextCentury Cloud Services:',
    models: [
      {
        name: 'Manual Read App',
        hyperlink: {
          label: 'More Info',
          url: 'https://support.nextcenturymeters.com/hc/en-us/articles/360045805731-Manual-Read-Properties',
        },
      },
      {
        name: 'Cellular Connectivity',
        hyperlink: {
          label: 'More Info',
          url: 'https://support.nextcenturymeters.com/hc/en-us/articles/360034785832-GW301-Cellular-Gateway-Data-Connection-Options',
        },
      },
      {
        name: 'Premium Data Hosting',
        hyperlink: {
          label: 'More Info',
          url: 'https://support.nextcenturymeters.com/hc/en-us/articles/360046325352-Subscriptions-and-Payments',
        },
      },
      {
        name: 'Leak Monitoring',
        hyperlink: {
          label: 'More Info',
          url: 'https://support.nextcenturymeters.com',
        }
      }
    ],
    learnMoreUrl: 'https://support.nextcenturymeters.com/hc/en-us/articles/360046325352-Subscriptions-and-Payments',
    sliderImg: 'assets/drawings/cloud-drawing.png',
  },
];
